import React from "react";
import Layout from "../../components/Layout";

export default function BetaAppRelease2023() {
  return (
    <Layout>
      <div className="flex justify-center">
        <div className="flex flex-col py-20 px-8 sm:px-12 lg:px-40 space-y-6 max-w-7xl">
          <div>
            <h2 className="uppercase">For Immediate Release</h2>
            <h3>
              Virufy Beta App Launched in Colombia, Providing At-Home COVID-19
              Detection to 4,500 Users
            </h3>
          </div>
          <div>
            <p>
              <em>Bogotá, Colombia – February 10, 2023</em> – Virufy, a
              cutting-edge smartphone app promising at-home COVID-19 diagnosis,
              has officially launched its private beta release in Colombia. The
              app is currently being utilized by 4,500 employees of a major
              medical insurance and diagnostics company. This technological
              advancement represents a significant step forward in accessible
              healthcare, allowing individuals to conveniently and safely
              monitor their COVID-19 status.
            </p>
          </div>
          <div>
            <p>
              <blockquote className="italic inline">
                “Virufy is an incredible tool to help protect the health of our
                patients”
              </blockquote>
              , says Dr. James, Chief Legal Officer at the company.
            </p>
          </div>
          <div>
            <p>
              The Virufy app leverages cutting-edge deep learning technology,
              including audio signal processing and spectrogram/MFCC processing,
              to classify patient forced cough and breathing samples for
              COVID-19 disease presence. This beta phase will allow Virufy to
              gather user feedback and optimize the app for wider distribution.
            </p>
          </div>
          <div>
            <p>
              Virufy is poised to revolutionize how individuals manage their
              health, particularly during a public health crisis. The app's
              accessibility and convenience empowers users to take proactive
              steps towards protecting themselves and their communities.
            </p>
          </div>
          <div>
            <h4 className="font-bold">About Virufy:</h4>
            <p>
              Virufy is a robust 501(c)(3) nonprofit company with 250
              volunteers/staff and 50 partner organizations including AWS,
              Pillsbury Law, and One Young World. We've developed a smartphone
              app which leverages AI to analyze the signature of recorded coughs
              to determine their similarity to COVID-19 and other diseases.
              Virufy is supported by faculty and alumni from Harvard, Stanford,
              UC Berkeley, MIT, and CMU.
            </p>
          </div>

          <div>
            <h4 className="font-bold">Contact:</h4>
            <div>Gabriela Sonsino</div>
            <div>Virufy Marketing Director</div>
            <a
              className="text-blue-600"
              href="mailto:gabriela.sonsino@virufy.org"
            >
              gabriela.sonsino@virufy.org
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}
